
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { ClaimDetailData, ClaimViewData, DocType } from '@/views/Claim/RT/types'
import { claimViewDetail, claimViewList, viewExport } from '@/API/claim/claimRT'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/claim/usePage'
import downloadFile from '@/utils/claim/downloadFile'
export default defineComponent({
    setup() {
        // 当前payout round统计数据
        const claimDetailData = ref<ClaimDetailData>({
            payoutRoundName: '',
            vinCount: 0,
            totalPassed: 0,
            totalFailed: 0,
            passedTotalAmount: 0
        })
        const router = useRouter()
        const payoutRoundId = router.currentRoute.value.query.id
        // 分页逻辑
        const { paginationObj, pageChange, sizeChange } = usePage()
        // 下拉
        const statusVal = ref('')
        const finalResultVal = ref()
        const vinVal = ref<string>('')
        const statusList = ref<DocType[]>([
            {label: 'All', value: ''},
            {label: 'Activated', value: 1},
            {label: 'Submitted', value: 2},
            {label: 'SICCO Checked', value: 3},
            {label: 'SIPO Confirmed', value: 7},
            {label: 'Spot Checked', value: 8},
            {label: 'Controlling Confirmed', value: 9},
            {label: 'Payment Initiated', value: 5},
            {label: 'Closed', value: 6}
        ])
        const finalResultList = ref<DocType[]>([
            {label:'Passed',value:1},
            {label:'Failed',value:2}
        ])
        const selectSize = ref<number>(20)
        const detailSize = ref<number>(50)
        // 表格数据 
        const tableHeight = window.innerHeight - 415
        const columns = reactive([
            { title: 'Product_Type', dataIndex: 'productType', width: 120 },
            { title: 'F&L', dataIndex: 'afcLcType', width: 100 },
            { title: 'Contract_Number', dataIndex: 'contractNumber', width: 150 },
            { title: 'Asset_Vin', dataIndex: 'assetVin', width: 170 },
            { title: 'Asset_Distributor', dataIndex: 'assetDistributor', width: 150 },
            { title: 'Manufacturer_Subsidy_Amt', dataIndex: 'manufacturerSubsidyAmt', width: 210 },
            { title: 'Proposal_Number', dataIndex: 'proposalNumber', width: 190 },
            { title: 'First_Submit_Date', dataIndex: 'firstSubmitDate', width: 155 },
            { title: 'Asset_Model', dataIndex: 'assetModel', width: 115 },
            { title: 'Contract_Financial_Product_Type', dataIndex: 'contractFinancialProductType', width: 240 },
            { title: 'Status', dataIndex: 'finalStatusStr', width: 110 },
            { title: 'Final Result', dataIndex: 'finalResultStr', width: 120 },
            { title: 'Failed Reason', dataIndex: 'failedReason', width: 270 }
        ])
        const claimViewData = ref<ClaimViewData[]>([])
        // 查询
        const searchParams = reactive({
            current: 0,
            size: 20,
            payoutRoundId: '',
            status: '',
            finalResult: '',
            vin: ''
        })
        const getViewList = () => {
            searchParams.payoutRoundId = `${payoutRoundId}`
            const params = searchParams
            claimViewList({params}).then((res) =>{
                const resData = res.payoutRoundViewPageVOList
                for (let i = 0; i < resData.length; i++) {
                    resData[i].firstSubmitDate = resData[i].firstSubmitDate.replace(/-/g,"/")
                }
                claimViewData.value = resData
                paginationObj.total = res.totalElements
            })
        }
        const searchListHandle = () => {
            searchParams.status = statusVal.value;
            searchParams.finalResult = finalResultVal.value;
            searchParams.vin = vinVal.value;
            getViewList()
        }
        // 重置
        const resetHandle = () => {
            searchParams.status = statusVal.value = '';
            searchParams.finalResult = finalResultVal.value = '';
            searchParams.vin = vinVal.value = '';
        }
        // export
        const exportHandle = () => {
            const params = {
                    url: `claimapi/payout/round/view/export?payoutRoundId=${payoutRoundId}`,
                    method: 'get',
                    fileName: `${+new Date()}.xlsx`
            }

            downloadFile(params, 'application/vnd-excel; char-set=UTF-8')
        }
        onMounted(() => {
            // 统计数据接口调用
            claimViewDetail({payoutRoundId: `${payoutRoundId}`}).then((res) => {
                claimDetailData.value = res
            })
            getViewList()
        })
        return {
            paginationObj,
            vinVal,
            statusVal,
            finalResultVal,
            statusList,
            finalResultList,
            pageChange,
            sizeChange,
            searchListHandle,
            exportHandle,
            resetHandle,
            selectSize,
            detailSize,
            claimDetailData,
            tableHeight,
            columns,
            claimViewData,
            searchParams,
            getViewList,
            payoutRoundId
        }
    },
})
